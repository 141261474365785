import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/vkuramshin/p/www/tkcrm/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Devices } from "./Devices";
import { Link } from "docz";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "gps-оборудования"
    }}>{`GPS оборудования`}</h1>
    <p>{`На данный момент в системе можно пользоваться оборудованиями следующих производителей:`}</p>
    <Devices mdxType="Devices" />
    <p>{`Сервер для взаимодейстия: `}<strong parentName="p">{`tracker.tkcrm.ru`}</strong></p>
    <blockquote>
      <p parentName="blockquote">{`В ближайшее время в системе можно будет использовать GPS оборудования более 200 производителей`}</p>
    </blockquote>
    <h2 {...{
      "id": "подключение"
    }}>{`Подключение`}</h2>
    <p>{`После создания объекта мониторинга (в нашем случае транспортное средство) идем на вкладку `}<strong parentName="p">{`Трекер`}</strong>{` и вводим IMEI оборудования как показано на скриншоте снизу.`}</p>
    <p>{`ID оборудования и SIM номера указывать не обязательно. Источник данных должен быть `}<strong parentName="p">{`Оборудование`}</strong></p>
    <p><img alt="connect tracker" src={require("../public/images/connect-gps.png")} /></p>
    <p>{`После поступления данных снизу автоматически настроятся большинство поддерживаем системных датчиков.`}</p>
    <p><img alt="system sensors" src={require("../public/images/system-sensors.png")} /></p>
    <p>{`Каждый системный датчик можно настроить по-своему, например указать отображение в мониторинге, выбрать формат уведомления и прочее.`}</p>
    <p><img alt="edit sensor" src={require("../public/images/edit-sensor.png")} /></p>
    <p>{`Подробнее об этом `}<a parentName="p" {...{
        "href": "/monitoring/sensors"
      }}>{`на этой странице`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      